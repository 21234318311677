import { render, staticRenderFns } from "./PageNotificationList.vue?vue&type=template&id=4f0886f2&scoped=true&"
import script from "./PageNotificationList.vue?vue&type=script&lang=js&"
export * from "./PageNotificationList.vue?vue&type=script&lang=js&"
import style0 from "./PageNotificationList.vue?vue&type=style&index=0&id=4f0886f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f0886f2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f0886f2')) {
      api.createRecord('4f0886f2', component.options)
    } else {
      api.reload('4f0886f2', component.options)
    }
    module.hot.accept("./PageNotificationList.vue?vue&type=template&id=4f0886f2&scoped=true&", function () {
      api.rerender('4f0886f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/notification/PageNotificationList.vue"
export default component.exports